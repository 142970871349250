import { Component } from "react";
import classNames from "classnames";
import styles from "assets/scss/components/AlertBanner.module.scss";
import Icon from "components/Icon";

export default class AlertBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isClient: false
        };
    }

    componentDidMount() {
        this.setState({
            isClient: true
        });
    }

    getDismissCookieName(prefix = 'dismissAlertBanner_') {
        if (this.props?.alertBanner?._id === undefined) {
            return false;
        }

        const factorsStr = JSON.stringify({
            id: this.props.alertBanner._id,
            title: this.props.alertBanner.title,
            linkText: this.props.alertBanner.linkText,
            url: this.props.alertBanner.url,
        });

        // Simple, non-crypto string hashing implementation
        let hash = 0;
        for (let i = 0; i < factorsStr.length; i++) {
            hash = ((hash << 5) - hash) + factorsStr.charCodeAt(i);
            hash &= hash;
        }
        const hashStr = (hash>>>0).toString(36);

        return `${prefix}${hashStr}`;
    }

    isDismissed() {
        const cookieName = this.getDismissCookieName();
        if (cookieName) {
            if (document.cookie.split(';').some(c => c.includes(`${cookieName}=1`))) {
                return true;
            }
        }

        return false;
    }

    setDismissed(maxAge = 31536000) {
        const cookieName = this.getDismissCookieName();
        if (cookieName) {
            document.cookie = `${cookieName}=1; max-age=${maxAge}`;
            this.forceUpdate();
        }
    }

    getBanner() {
        if (this.props?.alertBanner?._id && !this.isDismissed()) {
            return this.props.alertBanner;
        }

        return null;
    }

    render() {
        const banner = this.state.isClient ? this.getBanner() : null;
        if (!banner) {
            return null;
        }

        return <div className="row">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={classNames(this.props?.marginClass || 'mb-3', styles.alertBanner)}>
                            <span className={styles.dismiss} onClick={() => { this.setDismissed(); }}>
                                <Icon name="announcement_close" />
                            </span>
                            <div className={styles.textContainer}>
                                <div className={styles.title}><a target="_blank" href={banner.url}>{banner.title}</a></div>
                                <div className={styles.description}><a target="_blank" href={banner.url}>{banner.description}</a></div>
                            </div>
                            <a target="_blank" className={styles.ctaButton} href={banner.url}>
                                <span className={styles.ctaText}>{(banner.linkText || '').toUpperCase()}</span>
                                <span className={styles.ctaIcon}><Icon name="announcement_arrow" /></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

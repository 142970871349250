import { get } from "lodash";

export function getFullAthleteName(player, possessive = false) {
    let fullName = "";

    if (player.firstName && player.firstName.length > 0) {
        fullName += player.firstName
    }

    if (player.lastName && player.lastName.length > 0) {
        if (fullName.length > 0) {
            fullName += " ";
        }
        fullName += player.lastName
    }

    if (possessive) {
        fullName += "'";
        // either way we are adding an `'` but if the players name ends in an `s` thats it
        // else make it `'s`
        if (fullName.toLowerCase().charAt(fullName.length - 2) !== 's') {
            fullName += 's'
        }
    }

    return fullName;
}

export function getMostRecentRoster(player) {
    const mostRecentRosterPlayer = get((player.rosterPlayers || []).sort((a, b) => get(b, 'roster.year.year', 0) - get(a, 'roster.year.year', 0)), '0', null);
    return mostRecentRosterPlayer ? mostRecentRosterPlayer.roster : null;
}

export function getMostRecentSchool(player) {
    const roster = getMostRecentRoster(player);
    return roster?.team?.school;
}

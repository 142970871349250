import BaseLayout from "components/layout/BaseLayout";
import {withHeaderServerSideProps} from 'helpers/withHeaderServerSideProps';
import { getStories } from "lib/arc";
import moment from "moment";
import * as fragments from 'lib/fragments';
import addCacheTagging from "helpers/cacheTagging";
import TagManager from "react-gtm-module";
import { buildGlobalPageDataLayer } from "lib/dataLayer";
import { useState, useEffect} from "react";
import {parseRouterPath } from "lib/router-utils";
import NewsComponent from "components/News";
import { useRouter, withRouter } from 'next/router';
import AlertBanner from "components/AlertBanner";

const Home = ({...props}) => {
    const router = useRouter();

    const selectedPage = props.selectedPage ? props.selectedPage : 1;
    const [activePage, setActivePage] = useState(selectedPage);
    const [loading, setLoading] = useState(false);

    const handlePageSelected = page => {
        setLoading(true);

        // Uses the internal router to virtually re-route the application to reload the state
        const query = {
            ...router.query,
            page: page
        }
        // If page is manually set to 0 or negative then redirect to homepage
        if(page <= 1){
            window.location.href = '/high-school';
            return;
        }
        // Otherwise just send them to the news pages
        const { as } = parseRouterPath('/high-school/news/[page]', query, false);
        window.location.href = as;
    }

    const renderContent = () => {
        return (
            <NewsComponent selectedPage={activePage} onPageSelected={handlePageSelected} loading={loading} {...props}/>
        );
    }
    useEffect(()=> {
        TagManager.dataLayer({
            dataLayer: buildGlobalPageDataLayer('Home', props.loadForApp)
        });
        TagManager.dataLayer({ dataLayer: { event: 'data-layer-initialized' } });
    }, [] );

    return (
        <BaseLayout
            title="Utah High School Sports - Deseret News"
            description="Breaking news coverage and the latest updates on high school sports in Utah and Salt Lake City, including scores and schedules."
            content={renderContent()}
            canonicalUrl={'https://sports.deseret.com/high-school'}
            showSearchWidget={true}
            headers={<AlertBanner alertBanner={props.alertBanner} marginClass="mt-3" />}
            sidebar={false}
            {...props}
        />
    )
}

export default withRouter(Home);

export const getServerSideProps = withHeaderServerSideProps(async ({params= {}})=>{
    const pageNum = params.page || "1";
    const selectedPage = parseInt(pageNum);

    return {
        fragments: [
            fragments.PLAYERS_OF_THE_WEEK,
        ],
        additionalData: {
            storiesAndHeroes: await getStories({ sections: [ '/sports/high-school-sports' ], collection: 'dn-high-school-sports', page: selectedPage }),
            selectedPage
        },
        validation: [
            ({storiesAndHeroes, selectedPage}) => {
                if (storiesAndHeroes === false) {
                    return true;
                }
                if (selectedPage > 1) {
                    // we want to 404 if we go beyond the number of pages we have content for
                    return selectedPage === 1 || (selectedPage > 1 && storiesAndHeroes?.stories?.length > 0);
                }
                // Always try and load page 1
                return true;
            }
        ]
    }
});

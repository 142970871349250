import {getFullAthleteName, getMostRecentSchool} from "helpers/athlete";

const dataLayerDefaults = {
    'pageDetails.contentGrouping1': 'Sports',
    'pageDetails.contentGrouping2': 'High school sports',
    'pageDetails.topiclevel1': undefined,
    'pageDetails.topiclevel2': undefined,
    'pageDetails.topiclevel3': undefined,
    'pageDetails.topiclevel4': undefined,
    'pageDetails.topiclevel5': undefined,
    'pageDetails.playerprofile': undefined,
    'pageDetails.appView': undefined,
}

const stripEmptyKeys = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const buildOldSportNameFromSlug = (slug) => {
    switch(slug){
        case 'boys-basketball':
            return 'Basketball (Boys)';
        case 'girls-basketball':
            return 'Basketball (Girls)';
        case 'boys-soccer':
            return 'Soccer (Boys)';
        case 'girls-soccer':
            return 'Soccer (Girls)';
        case 'softball':
            return 'Softball';
        case 'baseball':
            return 'Baseball';
        case 'volleyball':
            return 'Volleyball';
        case 'boys-volleyball':
            return 'Volleyball';
        case 'girls-volleyball':
            return 'Volleyball';
        case 'football':
            return 'Football';
        case 'wrestling':
            return 'Wrestling';
        case 'boys-tennis':
            return 'Tennis (Boys)';
        case 'girls-tennis':
            return 'Tennis (Girls)';
        case 'cross-country':
            return 'Cross Country';
        case 'golf':
            return 'Golf';
        case 'track':
            return 'Track';
        case 'swimming':
            return 'Swimming';
        case 'boys-lacrosse':
            return 'Lacrosse (Boys)';
        case 'girls-lacrosse':
            return 'Lacrosse (Girls)';
        default:
            return '';
    }
}

export function buildGlobalPageDataLayer(pageName, appView){
    const pageSpecific = {
        'pageDetails.topiclevel1': pageName,
        'pageDetails.appView': appView ? appView : undefined
    }

    return stripEmptyKeys({
        ...dataLayerDefaults,
        ...pageSpecific
    });
}

export function buildSportsPageDataLayer(pageName, sport, appView){
    const pageSpecific = {
        'pageDetails.topiclevel3': pageName,
        'pageDetails.topiclevel2': buildOldSportNameFromSlug(sport.slug),
        'pageDetails.appView': appView ? appView : undefined
    }

    return stripEmptyKeys({
        ...dataLayerDefaults,
        ...pageSpecific
    });
}

export function buildSchoolPageDataLayer(school, appView){
    const pageSpecific = {
        'pageDetails.topiclevel2': school.name,
        'pageDetails.appView': appView ? appView : undefined
    }

    return stripEmptyKeys({
        ...dataLayerDefaults,
        ...pageSpecific
    });
}

export function buildTeamPageDataLayer(pageName, school, sport, appView){
    const pageSpecific = {
        'pageDetails.topiclevel4': school.name + ' - ' + buildOldSportNameFromSlug(sport.slug),
        'pageDetails.topiclevel5': pageName,
        'pageDetails.appView': appView ? appView : undefined
    }

    return stripEmptyKeys({
        ...dataLayerDefaults,
        ...pageSpecific
    });
}

export function buildAthletePageDataLayer(player, appView){
    const pageSpecific = {
        'pageDetails.playerprofile': getFullAthleteName(player) + ' - ' + getMostRecentSchool(player).name,
        'pageDetails.appView': appView ? appView : undefined
    }

    return stripEmptyKeys({
        ...dataLayerDefaults,
        ...pageSpecific
    });
}
